<template>
  <div>
    <section class="seccao" id="seccao-servicos">
      <header class="always-white">
        <div class="header-coluna-esq">
          <h2
            class="title-with-strap green-strap black"
            style="color: #000 !important"
          >
            O que é importante para a sua Farmácia?
          </h2>
        </div>
        <div class="header-coluna-dir">
          <div class="icon-search-mobile" @click="searchBoxShow()">
            <i id="icon-search-header" class="fas fa-search"></i>
          </div>
          <div v-if="user_name" @click="updateUserShow()">
            <i id="icon-user-header" class="far fa-user"></i>
          </div>
          <div v-if="!user_name" @click="loginForm()">
            <i id="icon-user-header" class="far fa-user"></i>
          </div>
          <div class="texto-font-2">Emotional Technology</div>
          <!-- <div class="title-with-strap">Guide</div> -->
        </div>
      </header>
      <Area />
      <SearchBox />
      <main class="servicos-outer-wrapper">
        <div class="servicos-wrapper">
          <div class="servicos-linha">
            <div class="servico-item" id="operacoes">
              <div
                class="servico-imagem"
                :style="{
                  'background-image': 'url(/assets/imagens/servico1.jpg)',
                }"
              ></div>
              <router-link
                :to="{ path: '/area/4-operacoes' }"
                class="servico-titulo links"
                style="background-color: #21b8b0"
                >Operações</router-link
              >
            </div>
            <div class="servico-item" id="suporte">
              <div
                class="servico-imagem"
                :style="{
                  'background-image': 'url(/assets/imagens/servico2.jpg)',
                }"
              ></div>
              <router-link
                :to="{ path: '/area/5-suporte' }"
                class="servico-titulo links"
                style="background-color: #e77d69; text-align: center !important"
                >Assistência e Informática</router-link
              >
            </div>
          </div>
          <div class="servicos-linha">
            <div class="servico-item" id="projetos">
              <div
                class="servico-imagem"
                :style="{
                  'background-image': 'url(/assets/imagens/servico3.jpg)',
                }"
              ></div>
              <router-link
                :to="{ path: '/area/2-projetos' }"
                class="servico-titulo links"
                style="background-color: #d89b8a"
                >Projetos</router-link
              >
            </div>
            <div class="servico-item" id="negocio">
              <div
                class="servico-imagem"
                :style="{
                  'background-image': 'url(/assets/imagens/servico4.jpg)',
                }"
              ></div>
              <router-link
                :to="{ path: '/area/1-negocio' }"
                class="servico-titulo links"
                style="background-color: #f8b42e"
                >Negócio</router-link
              >
            </div>
          </div>
          <div class="servicos-linha">
            <div class="servico-item" id="comunicacao">
              <div
                class="servico-imagem"
                :style="{
                  'background-image': 'url(/assets/imagens/servico5.jpg)',
                }"
              ></div>
              <router-link
                :to="{ path: '/area/3-comunicacao' }"
                class="servico-titulo links"
                style="background-color: #214695"
                >Comunicação</router-link
              >
            </div>
            <div class="servico-item" id="software">
              <div
                class="servico-imagem"
                :style="{
                  'background-image': 'url(/assets/imagens/servico6.jpg)',
                }"
              ></div>
              <router-link
                :to="{ path: '/area/6-software' }"
                class="servico-titulo links"
                style="background-color: #30b58e"
                >Software</router-link
              >
            </div>
          </div>
        </div>
      </main>
      <div class="novoBanner"></div>
      <div class="destaque-wrapper bg-color" @click="analyticsEventNovidades()">
        <div class="destaque-inner-wrapper">
          <div class="destaque-item width-20">
            <div class="destaque-item" style="width: auto">
              <h2 class="title-with-strap white">Blog</h2>
            </div>
            <div class="media-banner">
              <img
                src="@/assets/imagens/bg-seccao-full-screen.jpg"
                id="bg-seccao-full-screen"
              />
            </div>
          </div>
          <div class="destaque-item" style="width: 26%">
            <p class="white font-size-22">
              Aceda a artigos e conteúdos especializados com impacto na sua
              Farmácia.
              <!-- Aceda a todos os artigos <br />no
              <a class="link" href="https://blog.glintt.com/">Blog</a>. -->
              <!-- <span class="text-uppercase orange font-weight-600"
                  >portal é para si</span
                > -->
            </p>
            <!-- <p class="white font-size-22">
              Brevemente poderá consultar artigos informativos e casos de estudo
              desenvolvidos pela nossa equipa.
              <span class="text-uppercase orange font-weight-600"
                  >portal é para si</span
                >
            </p> -->
          </div>

          <!-- ATIVAR BLOG - DESCOMENTAR AQUI  -->
          <div class="destaque-item width-40 elemento-imagem">
            <div class="media-banner">
              <a
                href="https://blog.glintt.com/"
                class="button ghost diagonal grey"
                >Ir para o Blog</a
              >
            </div>
          </div>
          <!-- ATIVAR BLOG - -->
          <div class="destaque-item width-40 elemento-imagem">
            <div class="media-banner">
              <img src="@/assets/imagens/tablet1.png" id="tablet" />
              <img
                src="@/assets/imagens/bg-seccao-full-screen.jpg"
                id="bg-seccao-full-screen"
              />

              <!-- <a id="saber-mais" target="_blank" href="#" class="button ghost">
                Saber mais
              </a> -->
            </div>
          </div>
          <!-- <div class="destaque-item width-14 elemento-center">
            <a
              id="saber-mais"
              target="_blank"
              href="https://mailchi.mp/glintt/emotional-technology-21"
              class="button ghost"
            >
              Saber mais
            </a>
          </div> -->
          <!-- <div class="width-20 elemento-imagem">
            <a class="button ghost diagonal grey">Saiba Mais</a>
          </div> -->

          <!-- <a
            class="icone-botao"
            href="#un"
            v-smooth-scroll="{
              duration: 1500,
              offset: 60,
              container: '',
              updateHistory: false,
            }"
          >
            <div class="to-bottom_icon">
              <font-awesome-icon icon="arrow-down" color="white" />
            </div>
          </a> -->
        </div>
      </div>
      <Novidades />
      <transition name="fade" mode="out-in">
        <a
          class="to-top_icon"
          href="#seccao-servicos"
          v-smooth-scroll="{
            duration: 1500,
            offset: -100,
            container: '',
            updateHistory: false,
          }"
        >
          <!-- <div id="N_fixedBottom" v-if="scroll"> -->
          <font-awesome-icon icon="arrow-up" color="white" />
          <p
            style="font-size: 12px; color: white !important; text-align: center"
          >
            Voltar <br />ao início
          </p>
          <!-- </div> -->
        </a>
      </transition>
      <footer class="footer-contactos">
        <div class="footer-coluna-100p">
          <div class="flex-row align-center justify-space-between">
            <div class="container-contactos flex-row align-center">
              <div class="contacto-footer-item morada">
                <span class="icone-contacto"
                  ><img
                    src="@/assets/imagens/icone_morada.svg"
                    alt="icone morada"
                /></span>
                <span class="texto-contacto"
                  >Beloura Office Park Edf. 10, 2710-693 Sintra</span
                >
              </div>
              <div class="contacto-footer-item telefone">
                <span class="icone-contacto"
                  ><img
                    src="@/assets/imagens/icone_telefone.svg"
                    alt="icone telefone"
                /></span>
                <span class="texto-contacto"
                  ><a href="tel:+351219100200">+351 219 100 200</a></span
                >
              </div>
              <div class="contacto-footer-item fax">
                <span class="icone-contacto"
                  ><img src="@/assets/imagens/icone_fax.svg" alt="icone fax"
                /></span>
                <span class="texto-contacto"
                  ><a href="tel:+351219100299">+351 219 100 299</a></span
                >
              </div>
              <div class="contacto-footer-item emaial">
                <span class="icone-contacto"
                  ><img
                    src="@/assets/imagens/icone_email.svg"
                    alt="icone email"
                /></span>
                <span class="texto-contacto"
                  ><a href="mailto:info@glintt.com">info@glintt.com</a></span
                >
              </div>
              <div class="contacto-footer-item emaial">
                <span class="icone-contacto"
                  ><img
                    src="@/assets/imagens/icone_phone.svg"
                    alt="icone email"
                /></span>
                <span class="texto-contacto"
                  ><a>Assistência Técnica: 808 200 419</a></span
                >
              </div>
            </div>
            <div class="logotipo-footer">
              <img
                src="/assets/imagens/glintt_logo_footer.svg"
                alt="logotipo Glintt"
              />
            </div>
          </div>
          <div class="text-center">
            Copyright {{ getFullYear() }} | Boosted by
            <a href="https://byd.pt" target="_blank">BYD</a>
          </div>
        </div>
      </footer>
    </section>
  </div>
</template>

<script type="text/javascript" src="@/assets/ficheiros/home.js"></script>

<script>
  import Area from "@/components/Area.vue";
  import SearchBox from "@/components/SearchBox.vue";
  import Novidades from "@/views/Novidades.vue";

  export default {
    name: "home",
    components: {
      Area,
      SearchBox,
      Novidades,
    },
    props: ["backdoor"],
    data() {
      return {
        // backgroundImageUrl: "../assets/imagens/novo_banner_glintt.png",
        backgroundImageUrl:
          "https://bydservices.pt/imagens/novo_banner_glintt.png",
        url: "https://portal.glintt.com/assets/fotos_catalogo/products",
        destaques: {},
        n: 0,
        user_name: window.sessionStorage.getItem("name"),
        loadingStyle: "flex",
        scroll: false,
      };
    },
    computed: {
      // bannerHeight() {
      //   const image = new Image();
      //   console.log(image);
      //   image.src = this.backgroundImageUrl;
      //   console.log(`${image.height}px`);
      //   return `${image.height}px`;
      // },
      banner() {
        return this.$store.state.banners.find((i) => i.id == "1");
      },
      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      m() {
        this.backdoor;
        return this.n;
      },
      cartItems() {
        this.n;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
    },
    methods: {
      loadImageSize() {
        const bannerContainer = this.$refs.bannerContainer;
        const image = new Image();
        image.src = this.backgroundImageUrl;
        console.log(image, "image");
        image.onload = () => {
          bannerContainer.style.backgroundImage = `url(${image.src})`;
          bannerContainer.style.height = image.height + "px";
        };
      },
      getFullYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
      },
      async goToCart() {
        this.$router.push("/cart");
      },
      analyticsEventNovidades() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar no banner novidades",
          eventCategory: "int",
          eventAction: "contentview",
          eventLabel: "highlights",
        });
        ga(
          "send",
          "event",
          "Clicar no banner novidades",
          "contentview",
          "highlights"
        );

        console.log("evento");
      },
      handleScroll() {
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          this.scroll = true;
        } else {
          this.scroll = false;
        }
      },
      launch: async function () {
        var operation = "destaques";
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        var destaques = await this.call_api_get(url, headers);

        window.sessionStorage.setItem(
          "destaquesMap",
          JSON.stringify(destaques.destaques)
        );

        this.destaques = destaques;
      },
      logOut: async function () {
        window.sessionStorage.clear();
        this.$cookies.remove("username");
        this.$cookies.remove("token");
        window.location.href = "/";
      },
      updateUserShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = window.sessionStorage.name;
        document.querySelector("#novo-email").value =
          window.sessionStorage.email;

        document.querySelector("#area-screen").style.display = "block";
        document.querySelector(".home-section-1-bg").className =
          "home-section-1-bg ajust-top";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }, 200);
      },
      loginForm: async function () {
        document.querySelector("#area-screen").style.display = "block";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }, 200);
      },
      searchBoxShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#area-screen-search").style.display = "block";

        if (document.querySelector("#icon-search-header") != null) {
          document.querySelector("#icon-search-header").style =
            "color: #EA8822 !important;";
        }
        setTimeout(function () {
          document.querySelector("#area-screen-search").className =
            "home-section-1";
        }, 200);
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      async collectDestaques() {
        var operation = "destaques/listAll";
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        var destaques = await this.call_api_get(url, headers);

        window.sessionStorage.setItem(
          "destaquesMap",
          JSON.stringify(destaques.destaques)
        );
      },
    },
    watch: {
      destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
      },
    },
    async created() {
      this.launch();

      await this.$store.dispatch("collectBanners");

      this.collectDestaques();

      window.addEventListener("scroll", this.handleScroll);
      $(document).ready(function () {
        $(window).scroll(function () {
          if (
            $("body").height() <=
            $(window).height() + $(window).scrollTop()
          ) {
            // $(".to-bottom_icon").hide();
          }
        });
      });
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style>
  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    #seccao-servicos #user-area .home-section-1-bg {
      top: calc(-2.15vh - 1px);
      height: calc(100vh - 15vh + 2.15vh);
    }
    .ajust-top {
      height: calc(100vh - 15vh + 4.15vh) !important;
    }
  }
</style>

<style scoped>
  .width-20 {
    width: 20%;
  }
  .grey {
    color: #000 !important;
    cursor: pointer;
  }
  .grey::before {
    border-color: #fff !important;
    background-color: #fff !important;
  }
  .to-top_icon {
    position: absolute;
    right: 10vh;
    color: white;
    bottom: 30vh;
    background-color: #ff8e00;
    width: 100px;
    height: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
  }

  .to-bottom_icon {
    position: sticky;
    right: 0;
    background-color: #ff8e00;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    z-index: 1;
  }

  .icone-botao {
    color: white;
  }

  .title-with-strap.white::after,
  h2.title-with-strap.white::after {
    background-color: #ff8e00 !important;
  }

  #icon-user-header,
  #icon-search-header {
    font-size: 2.8vh !important;
    margin-right: 15px !important;
    display: block !important;
    /* transition: 700ms; */
    margin-left: 0 !important;
    color: black !important;
  }
  #icon-user-header:hover,
  #icon-search-header:hover,
  #icon-cart-header:hover {
    cursor: pointer;
  }
  #icon-cart-header {
    filter: invert(1);
    width: 33px;
    height: 33px;
  }
  #icon-cart-header-div {
    margin-right: 10px;
    position: relative;
    width: 38px;
    height: 34px;
  }

  main .servicos-wrapper .servicos-linha .servico-item:hover .servico-imagem,
  main .servicos-wrapper .servicos-linha .servico-item:focus .servico-imagem {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }

  .servicos-linha {
    position: relative;
  }

  #operacoes:hover .servico-titulo:after,
  #operacoes:focus .servico-titulo:after {
    top: 0;
    left: 25%;
    width: calc(25% - 40px);
    height: calc(100% - 40px - 40px + 40px);
    background: #fff;
    color: #21b8b0;
    padding: 20px;
    content: "Os melhores equipamentos para a sua operação do dia-a-dia";
    position: absolute;
    font-size: 23px;
    z-index: 99;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    /* padding-top: 60px; */
  }

  #suporte:hover .servico-titulo:after,
  #suporte:focus .servico-titulo:after {
    top: 0;
    left: 75%;
    width: calc(25% - 40px);
    height: calc(100% - 40px - 20px + 20px);
    background: #fff;
    color: #e77d69;
    padding: 20px;
    content: "Informática e Assistência 24h por dia";
    position: absolute;
    font-size: 23px;
    z-index: 99;
    text-align: center !important;
    font-weight: 700;
    display: flex;
    align-items: center;
    /* padding-top: 40px; */
  }

  #projetos:hover .servico-titulo:after,
  #projetos:focus .servico-titulo:after {
    top: 0;
    left: 0%;
    width: calc(25% - 40px);
    height: calc(100% - 40px - 40px + 40px);
    background: #fff;
    color: #d89b8a;
    padding: 20px;
    content: "Criamos a Farmácia dos seus sonhos";
    position: absolute;
    font-size: 23px;
    z-index: 99;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    /* padding-top: 50%; */
  }

  #negocio:hover .servico-titulo:after,
  #negocio:focus .servico-titulo:after {
    top: 0;
    left: 50%;
    width: calc(25% - 80px);
    height: calc(100% - 40px - 40px);
    background: #fff;
    color: #f8b42e;
    padding: 40px;
    content: "Queremos ajudá-lo na gestão do seu negócio";
    position: absolute;
    font-size: 23px;
    z-index: 99;
    text-align: center !important;
    font-weight: 700;
    display: flex;
    align-items: center;
    /* padding-top: 60px; */
  }
  .media-banner {
    align-items: center;
    display: flex;
  }
  #comunicacao:hover .servico-titulo:after,
  #comunicacao:focus .servico-titulo:after {
    top: 0;
    left: 25%;
    width: calc(25% - 40px);
    height: calc(100% - 40px - 40px + 40px);
    background: #fff;
    color: #214695;
    padding: 20px;
    content: "Comunique mais e melhor com os seus clientes";
    position: absolute;
    font-size: 23px;
    z-index: 99;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    /* padding-top: 60px; */
  }

  #software:hover .servico-titulo:after,
  #software:focus .servico-titulo:after {
    top: 0;
    left: 75%;
    width: calc(25% - 40px);
    height: calc(100% - 40px - 40px + 40px);
    background: #fff;
    color: #30b58e;
    padding: 20px;
    /* padding-top: 50%; */
    content: "Sifarma de A a Z";
    position: absolute;
    font-size: 23px;
    z-index: 99;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 60px; */
  }

  #tablet {
    position: absolute;
    left: 75%;
    max-width: 400px;
    z-index: 1;
  }

  #event-logo {
    width: 80%;
  }

  #bg-seccao-full-screen {
    position: absolute;
    left: 62.5%;
    width: 60%;
    max-height: 100%;
    z-index: 0;
    transform: skew(-15deg);
    -webkit-transform: skew(-15deg);
  }

  #saber-mais {
    color: white;
    background-color: #ff8e00;
    border-radius: 100px;
    margin-left: 10px;
    font-size: 18px !important;
  }
  #saber-mais::before {
    border: 1px solid #fff;
    background: #fff !important;
  }
  .ajust-top {
    height: calc(100vh - 12vh) !important;
    margin-top: calc(12vh + 7px) !important;
  }
  .logotipo-footer img {
    max-width: 70px;
  }
  footer.footer-contactos {
    height: 9vh !important;
  }
  section footer.footer-contactos .footer-coluna-100p {
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    align-self: flex-start;
  }
  .always-white {
    background-color: #fff !important;
  }
  .header-coluna-esq {
    background-color: #fff;
  }
  .links {
    text-decoration: none;
    color: inherit;
    width: 100%;
    position: inherit;
    margin: inherit;
  }
  #saber-mais:hover {
    cursor: pointer;
    font-weight: bold;
  }
  .orange-link:hover {
    cursor: pointer;
  }
  .header-relative {
    position: relative;
    top: 20px;
  }
  .big.relative {
    bottom: 50px;
  }
  .relative {
    position: relative;
  }
  .section-2 main .main-inner-wrapper .main-coluna-conteudo h2 {
    -webkit-text-fill-color: transparent;
    background: url("/assets/imagens/home_scroll_texto_3.jpg") repeat;
    background: -o-linear-gradient(transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .section-3 main .main-inner-wrapper .main-coluna-conteudo h2 {
    -webkit-text-fill-color: transparent;
    background: url("/assets/imagens/home_scroll_texto_4.jpg") repeat;
    background: -o-linear-gradient(transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .novoBanner {
    background: url("../assets/imagens/novo_banner_glintt.png");
    height: 145px;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .destaque-wrapper .destaque-item.elemento-imagem img {
    height: auto !important;
    max-height: 85%;
  }
  .destaque-wrapper .destaque-item .button {
    color: #fff !important;
  }

  .button.diagonal.ghost::before {
    background-color: #ff8e00 !important;
    border: none !important;
    color: #fff !important;
  }

  @media only screen and (min-width: 1080px) and (orientation: landscape) {
    footer.footer-contactos {
      height: 7.5vh !important;
    }
  }

  @media only screen and (max-width: 640px) {
    .media-banner {
      display: none;
    }

    #seccao-servicos #user-area .home-section-1-bg {
      top: calc(0vh);
      height: calc(100vh);
    }

    #icon-user-header {
      margin-right: 0px !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  @media only screen and (max-width: 1235px) {
    .novoBanner {
      height: 115px;
    }
  }
  @media only screen and (max-width: 970px) {
    .novoBanner {
      height: 90px;
    }
  }
  @media only screen and (max-width: 700px) {
    .novoBanner {
      height: 6vh;
    }
  }
  @media only screen and (max-width: 1024px) {
    .media-banner {
      display: none;
    }

    .icon-search-mobile {
      top: 45px !important;
    }
    #operacoes:hover .servico-titulo:after,
    #operacoes:focus .servico-titulo:after {
      top: 0;
      left: 0%;
      width: calc(50% - 40px);
      height: calc(50% - 40px - 40px + 40px);
      background: #fff;
      color: #21b8b0;
      padding: 20px;
      position: absolute;
      font-size: 23px;
      z-index: 99;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      /* padding-top: 60px; */
    }

    #suporte:hover .servico-titulo:after,
    #suporte:focus .servico-titulo:after {
      top: 50%;
      left: 50%;
      width: calc(50% - 40px);
      height: calc(50% - 40px - 20px + 20px);
      background: #fff;
      color: #e77d69;
      padding: 20px;
      position: absolute;
      font-size: 23px;
      z-index: 99;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      /* padding-top: 40px; */
    }

    #projetos:hover .servico-titulo:after,
    #projetos:focus .servico-titulo:after {
      top: 0;
      left: 0%;
      width: calc(50% - 40px);
      height: calc(50% - 40px - 40px + 40px);
      background: #fff;
      color: #d89b8a;
      padding: 20px;
      position: absolute;
      font-size: 23px;
      z-index: 99;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      /* padding-top: 50%; */
    }

    #negocio:hover .servico-titulo:after,
    #negocio:focus .servico-titulo:after {
      top: 50%;
      left: 50%;
      width: calc(50% - 80px);
      height: calc(50% - 40px - 40px);
      background: #fff;
      color: #f8b42e;
      padding: 40px;
      position: absolute;
      font-size: 23px;
      z-index: 99;
      text-align: center !important;
      font-weight: 700;
      display: flex;
      align-items: center;
      /* padding-top: 60px; */
    }

    #comunicacao:hover .servico-titulo:after,
    #comunicacao:focus .servico-titulo:after {
      top: 0;
      left: 0%;
      width: calc(50% - 40px);
      height: calc(50% - 40px - 40px + 40px);
      background: #fff;
      color: #214695;
      padding: 20px;
      position: absolute;
      font-size: 23px;
      z-index: 99;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      /* padding-top: 60px; */
    }

    #software:hover .servico-titulo:after,
    #software:focus .servico-titulo:after {
      top: 50%;
      left: 50%;
      width: calc(50% - 40px);
      height: calc(50% - 40px - 40px + 40px);
      background: #fff;
      color: #30b58e;
      padding: 20px;
      position: absolute;
      font-size: 23px;
      z-index: 99;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      /* padding-top: 60px; */
    }

    #event-logo {
      max-width: 150px !important;
    }
  }
  .link {
    text-decoration: none;
    color: #ff8e00;
    font-weight: bold;
  }

  .bg-color {
    background-color: #070707 !important;
  }
</style>
