<template>
  <section class="seccao header-fixo">
    <!-- NOVIDADES -->
    <section class="seccao-destaques">
      <div class="novidades">
        <!-- GALERIA -->
        <section
          class="ultimas-novidades"
          id="un"
          @click="analyticsEventNovidades()"
        >
          <div class="row">
            <div class="col-8" style="width: 79%; text-align: left !important">
              <h2 class="title-with-strap">Galeria</h2>
            </div>
            <!-- DESCOMENTAR PARA FILTROS  -->
            <div
              class="col-4"
              style="
                min-width: 20% !important;
                width: 20% !important;
                text-align: right !important;
              "
            >
              <multiselect
                class="filtroDropdown"
                v-model="filtros"
                placeholder="Filtrar"
                :options="options"
                :multiple="true"
                :taggable="false"
                :searchable="false"
              ></multiselect>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <span v-show="isLoading" class="loading-overlay is-overlay">
              <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
            </span>
          </transition>
          <div
            v-show="!isLoading"
            v-if="novidades.length > 0"
            class="slider"
            :style="{ marginTop: 0 }"
          >
            <!-- <transition name="fade" mode="out-in"> -->
            <!-- </transition> -->
            <div v-for="(item, j) in novidades" :key="j" class="slide">
              <a @click="openVideo(item.video)" class="btn-video-open links">
                <div class="photo-container">
                  <img
                    :src="`https://portal.glintt.com/assets/fotos_catalogo/products/${item.image}`"
                  />
                  <div class="overlay-video">
                    <i class="far fa-play-circle"></i>
                  </div>
                </div>
                <h3 class="orange-strap">{{ item.name }}</h3>
                <p id="paragraph">{{ item.description }}</p>
                <!-- <p>{{item.dt_created}}</p> -->
                <a class="button ghost diagonal">Saiba Mais</a>
              </a>
            </div>
          </div>
          <div v-if="novidades.length == 0">
            Não foram encontrados resultados na sua pesquisa...
          </div>
          <a
            class="icone-botao"
            href="#uc"
            v-smooth-scroll="{
              duration: 1500,
              offset: 60,
              container: '',
              updateHistory: false,
            }"
          >
            <div class="to-bottom_icon">
              <font-awesome-icon icon="arrow-down" color="white" />
            </div>
          </a>
        </section>

        <!-- ULTIMAS COMUNICACOES -->
        <section
          class="ultimas-comunicacoes"
          id="uc"
          @click="analyticsEventComunicacoes()"
        >
          <h2 class="subtitle title-with-strap">Últimas Comunicações</h2>
          <div class="slider">
            <div v-for="(item, j) in comunicacoes" :key="j" class="slide">
              <!-- USER NOT LOGGED IN -->
              <router-link v-if="!isLogged" :to="'/login'">
                <div class="photo-container">
                  <img
                    :src="`https://portal.glintt.com/assets/fotos_catalogo/products/${item.image}`"
                  />
                </div>
                <h3 class="orange-strap">
                  {{ item.name }}
                </h3>
                <p id="paragraph" style="max-width: 500px">
                  {{ item.description }}
                </p>
                <p style="max-width: 500px; font-weight: bold">
                  {{ item.expiration }}
                </p>
                <router-link
                  v-if="!isLogged"
                  class="button ghost diagonal"
                  :to="'/login'"
                  >Saiba Mais</router-link
                >
                <a
                  v-if="isLogged"
                  class="button ghost diagonal"
                  :href="item.link"
                  >Saiba Mais</a
                >
              </router-link>
              <!-- USER LOGGED IN -->
              <a v-if="isLogged" target="_blank" :href="item.link">
                <div class="photo-container">
                  <img
                    :src="`https://portal.glintt.com/assets/fotos_catalogo/products/${item.image}`"
                  />
                </div>
                <h3 class="orange-strap">{{ item.name }}</h3>
                <p id="paragraph" style="max-width: 500px">
                  {{ item.description }}
                </p>
                <p style="max-width: 500px; font-weight: bold">
                  {{ item.expiration }}
                </p>
                <router-link
                  v-if="!isLogged"
                  class="button ghost diagonal"
                  :to="'/login'"
                  >Saiba Mais</router-link
                >
                <a
                  v-if="isLogged"
                  class="button ghost diagonal"
                  target="_blank"
                  :href="item.link"
                  >Saiba Mais</a
                >
              </a>
            </div>
          </div>
          <a
            class="icone-botao2"
            href="#destaques"
            v-smooth-scroll="{
              duration: 1500,
              offset: 60,
              container: '',
              updateHistory: false,
            }"
          >
            <div class="to-bottom_icon2">
              <font-awesome-icon icon="arrow-down" color="white" />
            </div>
          </a>
        </section>
      </div>

      <!-- DESTAQUES -->
      <div
        class="destaques"
        id="destaques"
        @click="analyticsEventProdutosDestaque()"
      >
        <h2 class="title-with-strap">Produtos em destaque</h2>
        <div class="p font-size-24 font-weight-500">
          Se a eﬁciência do seu negócio é importante para si, também é
          importante para nós. Esta é a nossa sugestão:
        </div>
        <div class="slider3">
          <div v-for="(item, i) in destaquesMap" :key="i" class="slide">
            <router-link
              class="links produtos-destaque"
              :to="'/produto/' + item.id + '-' + item.name"
            >
              <div class="photo-container" style="height: 250px !important">
                <img :src="'assets/fotos_catalogo/products/' + item.image_1" />
              </div>
              <h3 class="produtos-h3">{{ item.name }}</h3>
              <p class="produto-p">{{ item.feed_description }}</p>
            </router-link>
          </div>
        </div>
      </div>

      <!-- <div class="loadingLayer" :style="'display: ' + loadingStyle">
        <img src="/assets/imagens/loading.gif" />
        <h3>A carregar...</h3>
      </div> -->

      <div class="overlay" :style="'display: ' + videoOpen">
        <div id="icon-close-video" @click="videoClose()">
          <i class="fas fa-times"></i>
        </div>
        <div id="videoLayer">
          <video
            v-if="videoPlaying != '' && !this.videoPlaying.includes('youtube')"
            style="max-width: 80vw; z-index: 1000"
            controls
            autoplay
          >
            <source
              :src="`assets/fotos_catalogo/products/${videoPlaying}`"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <iframe
            v-else
            width="100%"
            style="height: 100%"
            :src="`${videoPlaying}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </section>
    <!-- FARMALETTER -->

    <div class="destaque-wrapper">
      <div class="destaque-inner-wrapper">
        <div class="destaque-item width-20 farmlatter" style="width: 8%">
          <h2 class="title-with-strap white">Farmaletter</h2>
        </div>

        <div class="destaque-item farmaletter-texto" style="paddingleft: 15%">
          <h4 class="title-farmaletter">Já subscreveu a nossa newsletter?</h4>

          <p class="subtitle-farmaletter">Mantenha-se a par das novidades.</p>
        </div>

        <div class="destaque-item destaque-form" style="paddingright: 10%">
          <p style="color: white; marginbottom: 2px" v-if="email.length > 0">
            {{ message }}
          </p>
          <div class="btn form-email-farmaletter">
            <input
              class="form-input-email-farmaletter diagonal ghost"
              type="email"
              id="fname"
              v-model="email"
              placeholder="inserir email"
              required
              autocomplete="off"
            />
            <button
              id="submeterNewsletter"
              value="Submeter"
              @click="showModal()"
            >
              <span>Submeter</span>
            </button>

            <label class="label-farmaletter"
              >Pressione enter para enviar o email</label
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalNewsletter">
      <div id="popup">
        <button id="show-btn" class="buttonpop"></button>
        <div class="modal-overlay-newsletter" @click="fecharModal()"></div>
        <transition name="slide" appear>
          <div class="modal modalCookies">
            <div v-if="!submitedFarmaletter">
              <div style="padding-bottom: 50px"></div>
              <div class="container">
                <span
                  @click="
                    showModalNewsletter = false;
                    fecharModal();
                  "
                  class="w3-button w3-display-topright"
                  >&times;</span
                >
                <div class="col-4">
                  <div class="tabs_container">
                    <div
                      v-for="(tab, i) in tabs"
                      :key="i"
                      @click="selectedTab = tab.title"
                      :class="{ active: selectedTab == tab.title }"
                      class="tab-item"
                    >
                      <h3 class="tab-item__heading">{{ tab.title }}</h3>
                      <div style="color: #30b58e">{{ tab.subtitle }}</div>
                      <p class="tab-item__subheading"></p>
                    </div>
                  </div>
                </div>
                <div class="col-8" style="overflow-y: scroll; height: 40vh">
                  <template v-if="selectedTab == 'Subscrição Newsletter'">
                    <p>
                      Os seus dados pessoais poderão ser tratados pela
                      <b>GLINTT</b> - BUSINESS SOLUTIONS, LDA. (GLINTT BS) após
                      obtermos o seu consentimento prévio, unicamente para o
                      envio de comunicações sobre ofertas, produtos e serviços
                      da GLINTT BS.
                    </p>
                    <p>
                      <b
                        ><a
                          href="#"
                          style="color: #000"
                          @click="saberMais = !saberMais"
                          >Carregue para
                          <span
                            style="color: #30b58e; text-decoration: underline"
                            >saber mais</span
                          ></a
                        ></b
                      >
                    </p>

                    <ol
                      v-if="saberMais"
                      id="listaCookies"
                      style="list-style-type: decimal"
                    >
                      <li>
                        Os seus dados pessoais serão tratados pela GLINTT -
                        BUSINESS SOLUTIONS, LDA., com NIPC 503.502.537 e sede no
                        Beloura Office Park, Edifício 10, Quinta da Beloura,
                        2710-693 Sintra.
                      </li>
                      <li>
                        Sem os dados pessoais não poderemos efetuar o envio das
                        comunicações por email. Caso a GLINTT BS pretenda
                        utilizar os seus dados pessoais para outras finalidades,
                        iremos primeiro informar dessas finalidades e, se
                        aplicável, pedir o seu consentimento para as mesmas.
                      </li>
                      <li>
                        A GLINTT BS irá guardar estes dados pessoais durante 3
                        (três) anos, ou, até que retire o seu consentimento.
                      </li>
                      <li>
                        A GLINTT BS poderá comunicar os seus dados pessoais a
                        prestador(es) de serviços no âmbito do cumprimento da
                        finalidade descrita, sem prejuízo de comunicações que
                        resultem por obrigações legais e/ou regulamentares,
                        nomeadamente, junto a autoridades públicas.
                      </li>
                      <li>
                        Sem prejuízo do direito a apresentar reclamação à
                        autoridade de controlo (www.cnpd.pt), poderá exercer os
                        seus direitos previstos na legislação (acesso,
                        retificação, apagamento, limitação, oposição,
                        portabilidade) através do email privacidade@glintt.com e
                        pode retirar o seu consentimento a qualquer momento nas
                        próprias comunicações ou através do email
                        privacidade@glintt.com.
                      </li>
                      <li>
                        Pode saber mais sobre a forma como os seus dados serão
                        tratados através do email privacidade@glintt.com, ou,
                        contactando o nosso Encarregado da Proteção de Dados
                        através do email dpo@anf.pt.
                      </li>
                    </ol>
                    <p>
                      <b>
                        Compreendo e autorizo o envio de comunicações sobre
                        ofertas, produtos e serviços da GLINTT - BUSINESS</b
                      >
                    </p>
                  </template>
                </div>
              </div>
              <div class="button-wrapper text-right" style="margin-right: 5%">
                <!-- <a
									id="submitCookies"
									class="button ghost diagonal pointer"
									@click="fecharModal"
								>
									Não
								</a> -->

                <button
                  id="submitCookies"
                  @click="fecharModal"
                  class="button diagonal font-weight-500 text-uppercase pointer"
                  style="color: #fff !important; text-transform: capitalize"
                >
                  Não
                </button>
              </div>
              <div class="button-wrapper text-right">
                <!-- <a
									id="submitCookies1"
									class="button ghost diagonal pointer"
									@click="submitFarmLetter()"
								>
									Sim
								</a> -->
                <button
                  id="submitCookies1"
                  @click="submitFarmLetter()"
                  class="button diagonal font-weight-500 text-uppercase pointer"
                  style="color: #fff !important; text-transform: capitalize"
                >
                  Sim
                </button>
              </div>
            </div>
            <div v-else class="container-fluid">
              <span
                @click="
                  showModalNewsletter = false;
                  fecharModal();
                "
                class="w3-button w3-display-topright"
                >&times;</span
              >
              <div class="row">
                <div class="col">
                  <img class="thankyouImg" alt="" />
                  <!-- <img
										class="thankyouImg"
										src="../assets/imagens/glintt-subscricao-02.png"
										alt=""
									/> -->
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h2>Obrigado</h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>
                    O seu email foi adicionado à nossa lista e irá receber as
                    próximas edições da Farmaletter.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/slick.min.js"></script>
<script type="text/javascript" src="@/assets/ficheiros/scripts.js"></script>

<script>
  import Area from "@/components/Area.vue";
  import SearchBox from "@/components/SearchBox.vue";
  import Multiselect from "vue-multiselect";

  export default {
    name: "Novidades",
    components: {
      Area,
      SearchBox,
      Multiselect,
    },
    data() {
      return {
        saberMais: false,
        tabs: [
          {
            title: "Subscrição Newsletter",
            subtitle: "Aviso de Privacidade",
          },
        ],
        filtros: [],
        options: [
          "Testemunho",
          "Projetos",
          "Robótica",
          "Equipamentos",
          "Sifarma",
          "Assistência e Informática",
          "Inovação",
          "Comunicação",
          "Negócio",
          "Consumíveis",
          "Equipa",
        ],
        selectedTab: "Subscrição Newsletter",
        url: "https://portal.glintt.com/assets/fotos_catalogo/products",
        email: "",
        isLoading: false,
        message: "",
        isLogged: window.sessionStorage.user_level,
        novidades: [
          {
            img: "/assets/fotos-novidades/ola.jpg",
            video:
              "/assets/videos-novidades/Glintt_Filme_Teaser_Lançamento.mp4",
            title: "Emotional Technology Guide",
            date: "maio 2020",
            subtitle: "Bem-vindo à Nova Era da Farmácia",
          },
          {
            img: "/assets/fotos-novidades/farm-valentim.png",
            video: "/assets/videos-novidades/FARMACIA VALENTIM_FINAL.mp4",
            title: "Projeto - Farmácia Valentim",
            date: "maio 2020",
            subtitle: "Projeto integrado com a nossa solução de robótica",
          },
          {
            img: "/assets/fotos-novidades/minha_farm.png",
            video: "/assets/videos-novidades/MINHA FARMACIA V500.mp4",
            title: "Projeto – A minha farmácia",
            date: "maio 2020",
            subtitle: "Projeto desenvolvido de A a Z",
          },
        ],
        novidadesBU: [],
        comunicacoes: [],
        destaques: [
          {
            img: "destaques1.jpg",
            title: "Glory CI-10",
            subtitle: "Operações",
            url: "/produto/31",
          },
          {
            img: "destaques2.jpg",
            title: "Prima",
            subtitle: "Comunicação",
            url: "/produto/31",
          },
          {
            img: "destaques3.jpg",
            title: "Posologia",
            subtitle: "Suporte",
            url: "/produto/31",
          },
          {
            img: "destaques2.jpg",
            title: "Prima",
            subtitle: "Comunicação",
            url: "/produto/31",
          },
          {
            img: "destaques2.jpg",
            title: "Prima",
            subtitle: "Comunicação",
            url: "/produto/31",
          },
          {
            img: "destaques2.jpg",
            title: "Prima",
            subtitle: "Comunicação",
            url: "/produto/31",
          },
        ],

        loadingStyle: "flex",
        destaquesOld: [],
        videoOpen: "none",
        videoPlaying: "",
        backdoor: 0,
        showModalNewsletter: false,
        submitedFarmaletter: false,
      };
    },
    watch: {
      filtros: function (val) {
        this.filtrarNovidades();
      },
    },
    async created() {
      await this.prepareview();
    },
    async mounted() {
      await this.prepareview();

      $(".slider").slick({
        autoplay: true,
        loop: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      $(".slider_comunicacoes").slick({
        autoplay: true,
        loop: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      $(".slider2").slick({
        autoplay: true,
        loop: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      $(".slider3").slick({
        infinite: true,
        autoplay: true,
        loop: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
      // this.prepareview();
    },
    computed: {
      banner() {
        return this.$store.state.banners.find((i) => i.id == "2");
      },
      destaquesMap() {
        this.backdoor;
        return JSON.parse(window.sessionStorage.getItem("destaquesMap"));
      },
    },
    methods: {
      filtrarNovidades() {
        this.isLoading = true;
        var temp = [];
        var temp1 = [];
        $(".slider").slick("unslick");
        this.novidades = [];
        if (this.filtros.length == 0) {
          this.novidades = this.novidadesBU;
        } else {
          this.novidades = [];
          for (let i = 0; i < this.filtros.length; i++) {
            temp1 = this.novidadesBU.filter(
              (x) => x.category.indexOf(this.filtros[i]) > -1
            );
            if (temp1.length > 0) {
              for (let i = 0; i < temp1.length; i++) {
                temp.push(temp1[i]);
              }
            }
          }
          this.novidades = temp;
          this.novidades = this.novidades.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          );
        }
        setTimeout(() => {
          $(".slider").slick({
            autoplay: true,
            loop: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
              {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
          this.isLoading = false;
        }, 500);
      },
      fecharModal() {
        this.showModalNewsletter = false;
        this.email = "";
      },
      showModal() {
        let email = this.email;
        var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        setTimeout(() => {
          if (emailReg.test(email)) {
            this.showModalNewsletter = true;
          } else {
            this.message = "O email introduzido é inválido";
            this.showModalNewsletter = false;
          }
        }, 500);
      },
      async goToCart() {
        this.$router.push("/cart");
      },
      analyticsEventNovidades() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar nas fotos/videos novidades",
          eventCategory: "int",
          eventAction: "contentview",
          eventLabel: "new",
        });
        ga(
          "send",
          "event",
          "Clicar nas fotos/videos novidades",
          "contentview",
          "news"
        );
      },
      analyticsEventComunicacoes() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar nas comunicações",
          eventCategory: "consid",
          eventAction: "download",
          eventLabel: "new",
        });
        ga("send", "event", "Clicar nas comunicações", "download", "news");
      },
      analyticsEventProdutosDestaque() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar nos produtos em destaque",
          eventCategory: "int",
          eventAction: "contentview",
          eventLabel: "highlights",
        });
        ga(
          "send",
          "event",
          "Clicar nos produtos em destaque",
          "contentview",
          "highlights"
        );
      },
      prepareview: async function () {
        await this.$store.dispatch("collectBanners");

        //NOVIDADES - PRIMEIRA SEÇÃO - API TESTE
        var operation_novidades = "news/listAll";
        var headers_novidades = {};
        var url_novidades =
          "https://bydservices.pt/glintt-api/public/api/" + operation_novidades;

        var novidades = await this.call_api_get(url_novidades, headers);

        this.novidades = Object.values(novidades.news);
        for (let i = 0; i < this.novidades.length; i++) {
          if (this.novidades[i].category != null) {
            this.novidades[i].category = this.novidades[i].category.split(",");
          }
        }
        this.novidadesBU = Object.values(novidades.news);
        //PRODUTOS EM DESTAQUE
        var operation = "product/listAllFeatured";
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        var destaques = await this.call_api_get(url, headers);

        window.sessionStorage.setItem(
          "destaquesMap",
          JSON.stringify(destaques.products)
        );

        for (var i in destaques.page) {
          let e = destaques.page[i].img_carrossel.split(".").pop();
          if (e == "mp4") {
            destaques.page[i].type = "video";
          } else {
            destaques.page[i].type = "image";
          }
        }

        //ULTIMAS COMUNICACOES
        var operation_comunicacoes = "newsletter/listAll";
        var headers = {};
        var url_comunicacoes =
          "https://bydservices.pt/glintt-api/public/api/" +
          operation_comunicacoes;

        var ultimas_comunicacoes = await this.call_api_get(
          url_comunicacoes,
          headers
        );

        let newsletter = Object.values(ultimas_comunicacoes.newsletter);
        // let sortedNewsletter = newsletter.sort((a, b) => a.ordem - b.ordem);
        let sortedNewsletter = newsletter.sort(function (a, b) {
          if (a.ordem === b.ordem) {
            return b.id < a.id ? -1 : 1;
          }
          return a.ordem < b.ordem ? -1 : 1;
        });

        this.comunicacoes = sortedNewsletter;

        this.backdoor += 1;
        await this.$forceUpdate();

        // this.sliderSetup()

        let vm = this;
        setTimeout(function () {
          vm.loadingStyle = "none";
        }, 1000);
      },
      postFamrletter: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(headers, null, 2),
          });

          var result = await response.json();

          return result;
        } catch (error) {
          return "Error: " + error;
        }
      },
      submitFarmLetter: async function () {
        this.submitedFarmaletter = true;

        let email = this.email;
        let name = window.sessionStorage.name;

        let url_email =
          "https://bydservices.pt/glintt-api/public/api/farmaletter/create";

        let headers = {
          name,
          email,
        };

        //REQUISIÇÃO COM API para Farmaletter
        let res = await this.postFamrletter(url_email, headers);

        setTimeout(() => {
          if (res.resultado === "OK") {
            this.message = "Email enviado!";
            this.showModalNewsletter = false;
          } else {
            this.message = "Houve um problema ao enviar o email.";
            this.showModalNewsletter = false;
          }
        }, 5000);
      },
      async openVideo(src) {
        this.videoPlaying = src;
        this.videoOpen = "block";

        // this.videoPlaying = "https://www.w3schools.com/html/mov_bbb.mp4"
      },
      async videoClose() {
        this.videoPlaying = "";
        this.videoOpen = "none";
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          return "Error: " + error;
        }
      },
      logOut: async function () {
        window.sessionStorage.clear();
        this.$cookies.remove("username");
        this.$cookies.remove("token");
        window.location.href = "/";
      },
      updateUserShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = window.sessionStorage.name;
        document.querySelector("#novo-email").value =
          window.sessionStorage.email;

        document.querySelector("#area-screen").style.display = "block";
        document.querySelector(".home-section-1-bg").className =
          "home-section-1-bg ajust-top";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header-home").style =
            "color: #EA8822 !important;";
        }, 200);
      },
      searchBoxShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#area-screen-search").style.display = "block";

        if (document.querySelector("#icon-search-header") != null) {
          document.querySelector("#icon-search-header").style =
            "color: #EA8822 !important;";
        }
        setTimeout(function () {
          document.querySelector("#area-screen-search").className =
            "home-section-1";
        }, 200);
      },
    },
    beforeRouteLeave(to, from, next) {
      document.querySelector(".loadingLayer").style.display = "flex";
      next();
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/slick.css"></style>
<style src="@/assets/slick-theme.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  .produtos-h3 {
    font-size: 3vh !important;
  }

  .produto-p {
    font-size: 2vh !important;
  }

  .to-bottom_icon {
    /* position: sticky; */
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
    background-color: #ff8e00;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    z-index: 1;
  }
  .to-bottom_icon2 {
    position: relative;
    display: block;
    margin-left: auto;
    margin-bottom: 20px;
    background-color: #ff8e00;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    z-index: 1;
  }
  .to-bottom_icon2:hover {
    background-color: #ff8e00;
  }

  .icone-botao2 {
    color: white;
  }

  .produtos-h3 {
    font-size: 3vh !important;
  }

  .produto-p {
    font-size: 2vh !important;
  }

  .destaque-wrapper {
    background: linear-gradient(
      105deg,
      #ff8e00 23%,
      #000 23.1% 100%,
      transparent 50%
    );
  }

  .destaque-item.farmaletter-texto {
    padding-left: 0 !important;
  }
  .label-farmaletter {
    position: absolute;
    opacity: 0;
    transition: 0.2s bottom, 0.2s opacity;
    top: 5;
    left: 0;
    z-index: 1;
    font-size: 15px;
  }

  .title-farmaletter {
    color: rgb(255, 255, 255);
    font-size: 2.7vh;
    font-weight: 800;
    margin-bottom: 0;
  }

  .subtitle-farmaletter {
    color: rgb(255, 255, 255);
    font-size: 2.7vh;
    margin-top: 0;
    font-family: "Calibri Light";
    font-weight: 300 !important;
  }

  @media only screen and (max-width: 1080px) {
    .destaque-wrapper {
      background: linear-gradient(
        105deg,
        #ff8e00 26%,
        #000 26.1% 100%,
        transparent 50%
      );
    }

    .destaque-item.farmaletter-texto {
      padding-left: 55px !important;
    }

    .title-farmaletter {
      font-size: 2.3vh;
      font-weight: 800;
      margin-bottom: 0;
    }

    .subtitle-farmaletter {
      font-size: 2.3vh;
      font-weight: 300 !important;
    }

    .form-email-farmaletter {
      width: 150%;
    }
  }

  @media only screen and (max-width: 1366px) and (min-width: 1081px) {
    .destaque-wrapper {
      background: linear-gradient(
        105deg,
        #ff8e00 28%,
        #000 28.1% 100%,
        transparent 50%
      );
    }

    .destaque-item.farmaletter-texto {
      padding-left: 50px !important;
    }

    .title-farmaletter {
      font-size: 2.75vh;
      font-weight: 800;
      margin-bottom: 0;
    }

    .subtitle-farmaletter {
      font-size: 2.75vh;
      font-weight: 300 !important;
    }
  }

  @media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .destaque-wrapper {
      background: linear-gradient(
        105deg,
        #ff8e00 25%,
        #000 25.1% 100%,
        transparent 50%
      );
    }

    .title-farmaletter {
      font-size: 2.8vh;
      font-weight: 800;
      margin-bottom: 0;
    }

    .subtitle-farmaletter {
      font-size: 2.8vh;
      font-weight: 300 !important;
    }
  }

  #paragraph {
    height: 70px !important;
  }

  .form-email-farmaletter {
    width: 100%;
    border: rgba(255, 255, 255) 2px solid;
    transform-origin: bottom right;
    -ms-transform: skew(-15deg, 0deg);
    -webkit-transform: skew(-15deg, 0deg);
    transform: skew(-15deg, 0deg) !important;
    background: #fff;
    margin-top: 0;
    overflow: auto;
    border-right: 1px solid #fff;
  }

  .form-email-farmaletter-two {
    width: 100%;
    border: rgba(255, 255, 255) 2px solid;
    transform-origin: bottom right;
    -ms-transform: skew(-15deg, 0deg);
    -webkit-transform: skew(-15deg, 0deg);
    transform: skew(-15deg, 0deg) !important;
    background: #fff;
    margin-top: 0;
    overflow: auto;
    border-right: 1px solid #fff;
  }

  .button-farmaletter {
    color: black;
    background-color: white;
    font-family: "Calibri", sans-serif;
    font-size: 20px;
    font-weight: 800;
    height: inherit;
    padding: 10px 10px;
    cursor: pointer;
  }

  .button-farmaletter:active {
    color: white;
    background-color: black;
  }

  .form-input-email-farmaletter {
    background: rgba(0, 0, 0, 0);
    margin-top: 0;
    color: #000;
    font-family: var(--fonte-principal);
    font-family: "Calibri", sans-serif;
    padding-left: 5%;
    height: 35px;
    width: 58%;
    font-size: 20px;
    border: none;
    -moz-transform: skewX(15deg);
    -webkit-transform: skewX(15deg);
    -o-transform: skewX(15deg);
    -ms-transform: skewX(15deg);
    transform: skewX(15deg);
    float: left;
  }

  .form-input-email-farmaletter:focus {
    border: none;
  }

  .form-input-email-farmaletter:focus + .label-farmaletter {
    opacity: 1;
    margin-top: 20px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: rgb(255, 255, 255);
    font-family: var(--fonte-principal);
    font-family: "Calibri", sans-serif;
    font-size: 20px;
  }

  .overlay-video i {
    color: #fff;
    top: calc(50% - 50px);
    transform: translate(-50%, 0);
    position: absolute;
    font-size: 100px;
    left: 50%;
    opacity: 0.8;
  }
  .photo-container:hover .overlay-video i {
    opacity: 1;
  }

  .links:focus {
    outline: none;
  }
  .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 900;
  }

  .btn-video-open:hover {
    cursor: pointer;
  }

  #icon-close-video {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 25px;
  }

  #icon-close-video:hover {
    cursor: pointer;
  }

  #videoLayer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 80vw;
    background: #000;
    left: 10vw;
    top: 10vh;
    z-index: 999;
  }

  a {
    text-decoration: none;
  }

  .banner {
    margin-top: 134px;
    position: relative;
  }

  .banner img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }

  .banner-text {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
  }

  .banner .texto-font-2 {
    font-size: 36px;
    letter-spacing: 20px;
    display: inline-block;
    vertical-align: bottom;
  }

  .title-with-strap {
    color: black !important;
    margin: 0 !important;
  }

  .title-with-strap.white {
    color: white !important;
  }

  .banner .title-with-strap {
    font-size: 38px !important;
    vertical-align: bottom;
    margin-bottom: -16px;
  }

  .title-with-strap:after {
    background-color: #ff8e00;
  }

  .title-with-orange-strap:after {
    background-color: #ff8e00;
  }

  h2:after {
    background-color: #ff8e00;
  }

  .p {
    max-width: 100%;
  }

  .slide {
    display: inline-block;
    margin-right: 40px;
    position: relative;
    padding-bottom: 10px;
    transform: scale(0.85);
  }

  .ultimas-comunicacoes {
    background-color: #eaebeb;
  }
  .novidades .slide .photo-container {
    max-width: 500px;
  }

  .slide .photo-container {
    max-width: 500px;
    height: 350px;
    overflow: hidden;
    border: 4px solid transparent;
    position: relative;
  }

  .slide .photo-container:hover {
    border: 4px solid #ff8e00;
  }

  .ultimas-novidades,
  .ultimas-comunicacoes,
  .destaques {
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 80px;
  }

  .novidades .slide .photo-container:hover {
    border: 4px solid #ff8e00;
  }

  .slide .photo-container img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide h3 {
    font-size: 24px;
    color: black;
    margin-bottom: 10px;
    max-width: 500px;
  }

  .slide p {
    max-width: 500px;
    font-size: 16px;
    color: #ff8e00;
  }

  .novidades p {
    margin: 0;
    color: black;
  }

  .novidades .button {
    display: block;
    border-color: #ff8e00;
    color: #ff8e00;
    width: 120px;
    text-align: center;
    position: relative;
    margin-left: 6px;
    margin-top: 10px;
  }

  .novidades .button:hover {
    font-weight: 600;
  }

  .novidades .button:before {
    border-color: #ff8e00;
  }

  .slide h3:after {
    content: "";
    width: 30px;
    height: 6px;
    display: block;
    margin-top: 12px;
    background-color: #ff8e00;
  }
  .slide h3.orange-strap:after {
    content: "";
    width: 30px;
    height: 6px;
    display: block;
    margin-top: 12px;
    background-color: #ff8e00;
  }

  .img-bg {
    width: 500px;
    height: 350px;
    overflow: hidden;
    position: relative;
  }

  .bg {
    background-color: #214496;
    width: 500px;
    height: 300px;
    transform: skew(-15deg);
    position: absolute;
    top: 0;
    left: 0px;
  }

  .img-bg .photo-container {
    width: 100%;
    position: absolute;
    max-width: 400px;
    border: 4px solid transparent;
    box-sizing: border-box;
    overflow: hidden;
  }
  .img-bg .photo-container:hover {
    border: 4px solid #ff8e00;
  }
  .img-bg .photo-container img {
    width: 100%;
    position: absolute;
  }

  @media only screen and (max-width: 1250px) {
    .ultimas-novidades,
    .ultimas-comunicacoes,
    .destaques {
      padding-right: 50px;
      padding-left: 50px;
    }
    .banner-text {
      left: 50px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .banner-text {
      left: 50px;
    }
    .novidades .slide .photo-container,
    .img-bg .photo-container,
    .img-bg {
      width: 500px;
      height: 200px;
    }
    .banner .texto-font-2 {
      font-size: 20px;
    }
    .banner .title-with-strap {
      font-size: 25px !important;
    }
    .banner .subtitle {
      font-size: 15px;
    }
    .icon-search-mobile {
      top: 45px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .ultimas-novidades,
    .ultimas-comunicacoes,
    .destaques {
      padding-right: 20px;
      padding-left: 20px;
    }

    .banner-text {
      left: 20px;
    }
    .p {
      width: 100%;
    }
    .slide h3 {
      font-size: 30px;
    }
    .banner .texto-font-2 {
      font-size: 15px;
      letter-spacing: 8px;
    }
    .banner .title-with-strap {
      font-size: 20px !important;
    }
    .banner .subtitle {
      font-size: 10px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .slide {
      margin: 0 auto;
    }
    .novidades .button {
      position: relative;
      top: -1px;
      left: 5px;
      margin-top: 10px;
    }

    .slide .photo-container,
    .novidades .slide .photo-container {
      width: 90%;
    }

    .img-bg .photo-container,
    .img-bg {
      width: 100%;
    }
  }
  @media only screen and (max-width: 380px) {
  }
  @media only screen and (max-width: 640px) {
    #seccao-servicos #user-area .home-section-1-bg {
      top: calc(0vh);
      height: calc(100vh);
    }

    #icon-user-header-home {
      margin-right: 0px !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .always-white {
    background-color: #fff !important;
  }

  #icon-user-header-home,
  #icon-search-header-home {
    font-size: 2.8vh !important;
    margin-right: 15px !important;
    display: block !important;
    color: black !important;
  }
  #icon-user-header-home:hover,
  #icon-search-header-home:hover,
  #icon-cart-header-home:hover {
    cursor: pointer;
  }
  #icon-cart-header-home {
    filter: invert(1);
    width: 33px;
    height: 33px;
  }
  #icon-cart-header-div {
    margin-right: 10px;
    width: 33px;
    height: 33px;
  }
  #submeterNewsletter {
    float: right;
    height: 41px;
    font-size: 12px;
    width: 35%;
    padding: 5px 15px;
    border: 0;
    cursor: pointer;
    background-color: #000;
    border-left: 1px solid #000;
  }

  #submeterNewsletter span {
    color: #fff;
    display: inline-block;
    -webkit-transform: skewX(15deg);
    transform: skewX(15deg);
  }
  .button-wrapper.text-right {
    margin-right: 5%;
  }
  #submitCookies1,
  #submitCookies {
    width: 115%;
    text-align: center;
  }
  #submitCookies::before,
  #submitCookies1::before {
    background-color: #21bbb3 !important;
  }
  .modal-overlay-newsletter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background-color: #999;
    opacity: 1;
  }
  .destaque-wrapper .destaque-item {
    padding-left: 15px;
    padding-right: 15px;
    width: 30%;
  }

  .modalCookies .container-fluid {
    max-height: 100%;
    position: relative;
    top: 0;
    max-width: 100%;
    padding: 10%;
  }

  .modalCookies .container-fluid .col,
  .modalCookies .container-fluid h2,
  .modalCookies .container-fluid h5 {
    text-align: center;
    color: #2fb58d;
    width: 100%;
  }
  .modalCookies .container-fluid h2 {
    margin-bottom: 0;
  }

  .thankyouImg {
    max-width: 150px;
  }
  .multiselect__input {
    display: none !important;
  }
  .filtroDropdown .multiselect__option:after {
    display: none !important;
  }
</style>
<style>
  .filtroDropdown .multiselect__option:after {
    display: none !important;
  }
  .filtroDropdown .multiselect__option--highlight {
    background: #fff !important;
  }

  .multiselect__tag {
    background: #fff !important;
    border: 1px solid #d9d9d9;
  }

  .multiselect__element span:hover {
    background: #d9d9d9 !important;
    background-color: #d9d9d9 !important;
  }
  .multiselect__tag i:hover {
    background: #ff8e00 !important;
    background-color: #ff8e00 !important;
  }
  .multiselect__tag i:after {
    color: #000 !important;
  }
  .mt-3 {
    margin-top: 1.75rem !important;
  }
  input.delay {
    margin-bottom: 15px;
  }

  .loading-overlay {
    color: #ff8e00;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    z-index: 9999;
    height: 100% !important;
  }

  i.fa.fa-cog {
    font-size: 3em;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
</style>
