import { render, staticRenderFns } from "./Novidades.vue?vue&type=template&id=1a9c6f7c&scoped=true&"
import script from "./Novidades.vue?vue&type=script&lang=js&"
export * from "./Novidades.vue?vue&type=script&lang=js&"
import style0 from "@/assets/style.css?vue&type=style&index=0&lang=css&"
import style1 from "@/assets/slick.css?vue&type=style&index=1&lang=css&"
import style2 from "@/assets/slick-theme.css?vue&type=style&index=2&lang=css&"
import style3 from "@/assets/produtos.css?vue&type=style&index=3&lang=css&"
import style4 from "@/assets/responsive.css?vue&type=style&index=4&lang=css&"
import style5 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=5&lang=css&"
import style6 from "./Novidades.vue?vue&type=style&index=6&id=1a9c6f7c&scoped=true&lang=css&"
import style7 from "./Novidades.vue?vue&type=style&index=7&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a9c6f7c",
  null
  
)

export default component.exports