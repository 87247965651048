<template>
  <section id="area-screen-search" class="home-section-1 scrolled">
    <div id="user-area" class="home-section-1-inner-wrapper">
      <div class="home-section-1-bg" id="search-layer">
        <div id="user-area-inner-form">
          <div class="bg-diagonal-search"></div>
          <div id="search-area-close"><i class="fas fa-times"></i></div>
          <div id="form-inner">
            <div class="title-with-strap light-grey h1 big">
              Encontre tudo<br />o que precisa
            </div>
            <div id="form-fields" @click="analyticsEventSearch()">
              <div class="row">
                <div class="s-input-container">
                  <i class="fas fa-search"></i>
                  <input
                    type="text"
                    placeholder="nova pesquisa..."
                    v-model="pesquisa"
                    id="campo-pesquisa"
                  />
                </div>
              </div>
              <div class="row">
                <a
                  id="botao-actualizar"
                  class="button ghost"
                  @click="runSearch()"
                  ><div id="botao-actualizar-txt">Pesquisar</div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>

<script>
  export default {
    name: "SerachBox",
    data() {
      return {
        user_name: window.sessionStorage.getItem("name"),
        pesquisa: null,
      };
    },
    methods: {
      analyticsEventSearch() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Clicar no search",
          eventCategory: "int",
          eventAction: "contentview",
          eventLabel: "highlights",
        });
      },
      runSearch: async function () {
        if (this.$router.path != "/search") {
          this.pesquisa == null
            ? this.$router.push("/search")
            : this.$router.push("/search?q=" + this.pesquisa);
        } else {
          window.location.search = "q=" + this.pesquisa;
        }

        $(".home-section-1").addClass("scrolled");
        setTimeout(function () {
          if (document.querySelector("#icon-search-header") != null) {
            document.querySelector("#icon-search-header").style =
              "color: #000 !important;";
          }
          if (document.querySelector("#icon-search-header-home") != null) {
            document.querySelector("#icon-search-header-home").style =
              "color: #000;";
          }
          if (document.querySelector("#icon-search-header-area") != null) {
            document.querySelector("#icon-search-header-area").style =
              "color: #000;";
          }
        }, 500);
        setTimeout(function () {
          document.querySelector("#area-screen-search").style.display = "none";
        }, 1500);
      },
    },
    mounted() {
      $(document).ready(function () {
        $("#search-area-close").click(function () {
          $(".home-section-1").addClass("scrolled");
          setTimeout(function () {
            if (document.querySelector("#icon-search-header") != null) {
              document.querySelector("#icon-search-header").style =
                "color: #000 !important;";
            }
            if (document.querySelector("#icon-search-header-home") != null) {
              document.querySelector("#icon-search-header-home").style =
                "color: #000000;";
            }
            if (document.querySelector("#icon-search-header-area") != null) {
              document.querySelector("#icon-search-header-area").style =
                "color: #000;";
            }
          }, 500);
          setTimeout(function () {
            document.querySelector("#area-screen-search").style.display =
              "none";
          }, 1500);
        });
      });
      let app = this;
      document
        .querySelector("#campo-pesquisa")
        .addEventListener("keypress", function (e) {
          e.keyCode == 13 ? app.runSearch() : false;
        });
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .light-grey {
    color: #999 !important;
  }
  .big {
    font-size: 66px !important;
  }
  .title-with-strap:after {
    background-color: #999 !important;
  }

  .fa-search {
    color: #2fb68e;
    display: inline-block;
    position: absolute;
    font-size: 22px;
    bottom: 9px;
  }
  .s-input-container {
    position: relative;
    width: 100%;
  }
  .s-input-container input {
    padding: 8px 35px !important;
    border-bottom: 1px solid #21bbb3;
    margin-top: 100px;
  }
  .s-input-container input::placeholder {
    font-size: 17px;
  }
  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    .bg-diagonal-search {
      background-color: white;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0px;
      height: 100%;
      transform: skew(-15deg, 0deg);
      opacity: 0.95;
    }
    #user-area {
      left: 50vw;
      width: 50vw;
    }
    #user-area-inner-form {
      width: 50vw;
      left: 0px;
      height: calc(100%);
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
    #search-area-close {
      margin-left: auto;
      color: #222222;
      padding: 15px;
      height: 15px;
      z-index: 999;
      font-size: 20px;
      padding-right: 20px;
    }
    #search-area-close:hover {
      cursor: pointer;
    }
    #form-inner {
      color: #222222;
      padding: 15px;
      height: fit-content;
      width: 75%;
      display: flex;
      align-self: start;
      flex-direction: column;
      /* font-family: "Calibri", sans-serif; */
      letter-spacing: 0.5px;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 30px;
      position: relative;
      left: 20%;
    }
    #form-inner div:not(#botao-actualizar-txt) {
      margin: 10px;
      color: #ffffff;
    }
    .diagonal-form {
      padding: 0.8rem 2rem;
      font-size: 16px;
      font-weight: 600;
      color: #000000 !important;
      display: inline-block;
      position: relative;
      z-index: 1;
      text-decoration: none;
      border: none;
      width: fit-content;
    }
    .diagonal-form::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      transform: skew(-15deg);
      -webkit-transform: skew(-15deg);
      display: inline-block;
      z-index: -1;
    }

    #log-out {
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 15px;
      right: 15px;
      font-size: 80%;
      color: #fff;
    }
    #log-out i {
      margin-left: 5px;
    }
    #log-out:hover {
      cursor: pointer;
      font-weight: bold;
    }
    #form-fields {
      color: #222222;
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0px;
    }
    .row {
      display: flex;
      align-self: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      width: 100%;
      margin-bottom: 20px;
      font-size: 16px;
    }
    .row div {
      align-self: flex-start;
      min-width: 30%;
    }
    .field {
      min-width: 85% !important;
    }
    .field i {
      margin-right: 10px;
      min-width: 20px;
      color: #21bbb3 !important;
    }
    /* .row input {
            display: inline-block;
            width: 40% !important;
            border: none;
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-bottom: 1px solid #21BBB3;
            color: #000;
            padding: 2px 0 !important;
            font-family: "Calibri", sans-serif;
            font-size: 15px;
            font-weight: 600 !important;
            border-radius: 0;
            -webkit-appearance: none;
        } */
    .row input::placeholder {
      color: #545454;
      font-weight: 300;
    }
    #botao-actualizar {
      margin-left: 70%;
      width: fit-content;
      margin-top: 80px;
      color: #fff;
      border-color: #2fb68e;
      background-color: #2fb68e;
      transform: skew(-15deg);
      -webkit-transform: skew(-15deg);
    }
    #botao-actualizar:hover {
      cursor: pointer;
      font-weight: bold;
    }
    #botao-actualizar-txt {
      transform: skew(15deg);
      -webkit-transform: skew(15deg);
      color: inherit;
      margin: 0;
    }
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg {
      width: 100vw;
      height: calc(100vh - 13vh);
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--cor-2);
      background-color: transparent;
      margin-top: calc(50px + 14px + 6.75vh);
      margin-bottom: auto;
    }

    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
      display: none;
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
      filter: FlipV;
      -ms-filter: "FlipV";
      left: calc(-20%) - 1px;
      opacity: 0.95;
    }

    .home-section-1 {
      z-index: 900;
      position: fixed;
      overflow-y: hidden;
      display: none;
    }
  }

  @media only screen and (max-width: 640px) {
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
      display: none;
    }
    #seccao-servicos #user-area .home-section-1-bg {
      height: 100vh;
      width: 100vw;
      top: 0;
    }
    #form-inner {
      position: absolute;
      width: calc(100vw - 30px);
    }
    #log-out {
      position: fixed;
      bottom: 15px;
      right: 15px;
      color: #fff;
    }
    #user-area {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999;
    }
    #user-area-inner-form {
      width: 100vw;
      height: 100vh;
    }
    #form-fields {
      width: calc(100% - 20px);
    }

    .row {
      flex-direction: column;
      width: 90%;
    }
    .field {
      min-width: 100% !important;
      margin-left: 0px !important;
    }
    .row input {
      width: 100% !important;
    }
    #botao-actualizar {
      margin-left: 0;
      align-self: flex-end;
    }
    #search-area-close {
      z-index: 999;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .bg-diagonal-search {
      background-color: white;
      width: 100%;
      position: absolute;
      top: 0;
      /* bottom: 0; */
      right: 0px;
      height: 100%;
      opacity: 0.95;
    }
  }
</style>
